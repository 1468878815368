import Image1 from "../../assets/fruwa2.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Fruwa",
    description:
      "Did a really good job with my site, included everything I wanted and styled it perfectly!",
  },
];
