import Work1 from "../../assets/racoon.png";
import Work2 from "../../assets/fruwa2.png";
import Work3 from "../../assets/rac2.jpeg";
import Work4 from "../../assets/ecom.jpeg";
import Work5 from "../../assets/weather.png";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: <a href="https://shumecki.com">Shumecki Site <i className="bx bx-right-arrow-alt work__button-icon"></i> </a>,
    category: "web",
  },
  {
    id: 2,
    image: Work2,
    title: <a href="https://fruwachannel.com">Fruwa Site <i className="bx bx-right-arrow-alt work__button-icon"></i> </a>,
    category: "web",
  },
  {
    id: 3,
    image: Work3,
    title: <a href="https://giliaraguy.github.io/Raccon-Jump/">Raccon Jump Game <i className="bx bx-right-arrow-alt work__button-icon"></i> </a>,
    category: "app",
  },
  {
    id: 4,
    image: Work4,
    title: "Typescript Apple Site Redesign",
    category: "design",
  },
  {
    id: 5,
    image: Work5,
    title: "Weather App",
    category: "app",
  },
];

export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
